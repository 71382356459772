<template>
<div class="tips">
  <p class="in_tip" v-text="tip_data"></p>
  <p class="in_tip" v-text="tip_from" style="margin-right:40px;"></p>
</div>
</template>

<script>
export default {
  data(){
    return{
      tip_data:"加载中...",
      tip_from:"",
    }
  },
  created(){
    this.get_hitokoto();
    setInterval(()=>{
      this.get_hitokoto();
    },5*60000)
  },
  methods:{
    get_hitokoto(){
      this.$axios.get('https://v1.hitokoto.cn')
      .then(({ data }) => {
        this.tip_data = data.hitokoto
        this.tip_from = "—— " + data.from
      })
      .catch(console.error)
    }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Kanit:900");
.in_tip{
  text-align: right;
  font-size: 28px;
  color: #fff;
  text-shadow:
  0 1px 0#deafaf,
  0 2px 0#dba8a8,
  0 3px 0#d8a1a1,
  0 4px 0#d59999,
  0 5px 0#d29292,
  0 6px 0#cf8b8b,
  0 7px 0#cc8484,
  0 8px 0#c97d7d,
  0 0 5px rgba(231,156,156,0.05),
  0 -1px 3px rgba(231,156,156,0.2),
  0 9px 9px rgba(231,156,156,0.3),
  0 5px 5px rgba(231,156,156,0.3),
  0 5px 5px rgba(231,156,156,0.3);
  /* margin-bottom: -20px; */
  margin: 16px;
}

</style>