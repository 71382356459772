<template id="Clock">
<div class="clock">
  <div class="wenhou" v-text="thisDate +' '+ wenhou"></div>
  <div class="time">
    <p v-text="hh<10?0:Math.floor(hh/10)"></p>
    <p v-text="hh%10"></p>
    <p>:</p>
    <p v-text="mm<10?0:Math.floor(mm/10)"></p>
    <p v-text="mm%10"></p>
    <p>:</p>
    <p v-text="ss<10?0:Math.floor(ss/10)"></p>
    <p v-text="ss%10"></p>
  </div>
</div>
</template>

<script>
export default {
  data(){
    return{
      thisDate: "",
      wenhou:"",
      hh: "",
      mm: "",
      ss: ""
    }
  },
  created(){
    this.timeHandle();
    setInterval(this.timeHandle,1000)
  },
  methods:{
    timeHandle(){
      let now = new Date();
      this.thisDate = now.toLocaleDateString();
      this.hh = now.getHours();
      this.mm = now.getMinutes();
      this.ss = now.getSeconds();

      if(this.hh<3){
        this.wenhou = "深夜了"
        return
      }
      if(this.hh<6){
        this.wenhou = "凌晨了"
        return
      }
      if(this.hh<9){
        this.wenhou = "早上了"
        return
      }
      if(this.hh<11){
        this.wenhou = "上午了"
        return
      }
      if(this.hh<13){
        this.wenhou = "中午了"
        return
      }
      if(this.hh<18){
        this.wenhou = "下午了"
        return
      }
      this.wenhou = "晚上了"
      return
    }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Kanit:900");
.wenhou{
  text-align: right;
  font-size: 40px;
  color: #fff;
  text-shadow:
  0 1px 0#deafaf,
  0 2px 0#dba8a8,
  0 3px 0#d8a1a1,
  0 4px 0#d59999,
  0 5px 0#d29292,
  0 6px 0#cf8b8b,
  0 7px 0#cc8484,
  0 8px 0#c97d7d,
  0 0 5px rgba(231,156,156,0.05),
  0 -1px 3px rgba(231,156,156,0.2),
  0 9px 9px rgba(231,156,156,0.3),
  0 12px 12px rgba(231,156,156,0.3),
  0 15px 15px rgba(231,156,156,0.3);
  margin-bottom: -20px;
}
.time{
  display: flex;
  justify-content: center;
}
.time p{
  width: 50px;
  text-align: center;
  font-family: Kanit;
  font-size: 75px;
  color: #fff;
  margin: -3px;
  text-shadow:
  0 1px 0#deafaf,
  0 2px 0#dba8a8,
  0 3px 0#d8a1a1,
  0 4px 0#d59999,
  0 5px 0#d29292,
  0 6px 0#cf8b8b,
  0 7px 0#cc8484,
  0 8px 0#c97d7d,
  0 0 5px rgba(231,156,156,0.05),
  0 -1px 3px rgba(231,156,156,0.2),
  0 9px 9px rgba(231,156,156,0.3),
  0 12px 12px rgba(231,156,156,0.3),
  0 15px 15px rgba(231,156,156,0.3);
}
.time p:nth-child(3n){
  width: 32px;
} 

</style>