<template>
    <h3 id="bottom">
    <!-- 网站创建日期2021年12月8日8点8分 -->
    <p id="bei">
      <span>小破站已稳定运行{{ runDate() }}天</span><span>备案号：<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022024459号-1</a></span>
    </p>
    <p>COPYRIGHT © 2021 929440619@qq.com AllRightsReserved.</p>
  </h3>
</template>

<script>
export default {
  data: function () {
    return {
    };
  },
  methods: {
    runDate() {
      let startDate = Date.parse("2021-12-8 00:00:00");
      let endDate = Date.parse(Date());
      let leave = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return Math.floor(leave);
    },
  },
};
</script>

<style scoped>
#bottom {
  display: flex;
  flex-direction: column;
  background-color: rgba(160, 63, 79, 0.3);
  color: darkcyan;
  font-weight: 300;
  font-size: 18px;
  font-style: normal;
  width: 100%;
  z-index: 100;
}
#bottom p {
  margin: 0 auto;
  padding: 5px;
}
#bei{
  display: flex;
  justify-content: center;
}
#bei span{
  margin: 0 10px;
}
</style>