<template>
  <div class="home">
    <tips class="tips"></tips>
    <clock class="clock"></clock>
    <div class="kaifa" v-show="isShow">
      <p>haniya的网站开发中。。。</p>
      <p>当前页面：haniya制作的动态壁纸</p>
      <a href="#" @click="hidd">隐藏</a>
      <a href="#" @click="change" style="margin:20px;">切换</a>
    </div>
    <video src="@/static/nyatip_03.mp4" autoplay loop muted="false" v-show="isChange"></video>
    <img src="@/static/2233gx.png" alt="" width="100%">
    <copy-right id="copyright" v-show="isShow"></copy-right>
  </div>
</template>

<script>
import Clock from '../components/Clock.vue';
import Tips from '../components/Tips.vue';
// import Tips from '../components/haniya.vue';
import CopyRight from '../components/copyRight.vue';

export default {
  name: 'Home',
  data(){
    return {
      isShow: true,
      isChange: false
    }
  },
  methods:{
    hidd(){
      this.isShow = !this.isShow;
    },
    change(){
      this.isChange = !this.isChange;
    }
  },
  components: {
    Clock,
    Tips,
    CopyRight
  },
}
</script>

<style>
.kaifa{
  position: absolute;
  text-align: center;
  width: 100%;
  box-shadow: 0 0 10px #2e2e2e;
  background: hsla(0,0%,100%,.2);
  color: #39c5bb;
}
.home{
  padding: 0;
}
.clock {
  position: absolute;
  user-select: none;
  bottom: 60px;
  right: 60px;
  z-index: 1;
}
.tips {
  position: absolute;
  user-select: none;
  bottom: 70px;
  left: 40px;
  z-index: 1;
}
video{
  width: 100%;
  z-index: 0;
}
  #copyright{
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 10px #2e2e2e;
    background: hsla(0,0%,100%,.2);
    color: #39c5bb;
    margin: 0;
  }
</style>
